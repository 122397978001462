import React from "react";
import { countries } from "../../global/countriesAndSources";
import etoille from "../../media/star.png"
import "./GoogleCSE.css"

export const CountryBar = (props) => {
    const countrySelected = props.selectedCountry;
    const funChangeCountry = props.setSelectedCountry;
    const funChangeDisplayMap = props.displayMap;
    const funSearchThematics = props.searchThematics;
    const themes = props.thematics;

    const getARandomCountry = () => {
        const country = countries[Math.floor(Math.random() * countries.length)]
		Array.from(document.getElementsByClassName('divli')).find((c) => c.innerText === country.name).scrollIntoView({ behavior: 'smooth'})
		funChangeCountry(country)
	}

    return (
        <>
        <input type="text" id="searchCountry" className="m-4 w-4/5 border-2 border-cyan-600" onKeyUp={() => {
							var input, filter, div, a, i;
							input = document.getElementById("searchCountry");
							filter = input.value.toUpperCase();
							div = document.getElementById("country_box");
							a = div.getElementsByClassName("country_selection");
							console.log(a.length)
					
							for (i = 0; i < a.length; i++) {
								let txtValue = a[i].textContent || a[i].innerText;
								if (txtValue.toUpperCase().indexOf(filter) > -1) {
									a[i].style.display = "";
								} else {
									a[i].style.display = "none";
								}
							}
						}}></input>
            <div className="ulflag">
                <div id="country_box">
                        <ul className="divli" onClick={() => { funChangeDisplayMap() } } key="mapdisplay"> 
                            <li className="country_selection" key="mapdisplaychild"> 
                                <div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
                                <a href="#">Afficher la carte</a>
                            </li>
                        </ul>
                        <ul className="divli" onClick={() => { getARandomCountry() } } key="random"> 
                            <li className="country_selection" key="randomchild"> 
                                <div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
                                <a href="#">Aléatoire</a>
                            </li>
                        </ul>
                    {
                        countries.map((c, i) => 
                        <React.Fragment key={`country-${c.code}`}>
                           
                            <ul className="divli" onClick={() => { funChangeCountry(c) } } > 
                                <li className="country_selection" > 
                                    <div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
                                    <img src={process.env.PUBLIC_URL + '/flags/' + c.code + ".svg"} alt={c.name} /><a href="#">{c.name}</a>
                                </li>
                            </ul>
                            { 
                                themes && c.code === countrySelected.code && (
                                    themes.map((item, index) => 
                                        <div key={`theme-${item.theme}`}>
                                            <button 
                                    key={index} 
                                    onClick={() => funSearchThematics(item.theme)}
                                    style={{
                                        textAlign: "left",
                                        margin: "0.5em",
                                    }}
                                    >
                                            {item.theme}
                                        </button>
                                        </div>
                                    )
                                )
                            }
                        </React.Fragment>
                        )
                    }
                </div>
            </div>
        </>
    )
}