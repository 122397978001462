import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListSubheader from "@mui/material/ListSubheader";

import Collapse from "@mui/material/Collapse";
import Logo from "../../media/logonobg.png";
import React, { useContext, useState, useEffect } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Context } from "../../Helper/Context";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import etoille from "../../media/start.jpeg"
import Noetoille from "../../media/startred.png"
import h from "../../media/h.png"
import bookmark from "../../media/fav.jpeg"
import amis from "../../media/amis.png"
import message from "../../media/message.png"
import Chroniques from "../../media/ICONS THEMATIQUE/Fichier 5_1.png"
import Societe from "../../media/ICONS THEMATIQUE/Fichier2.png"
import culture from "../../media/ICONS THEMATIQUE/Fichier 3.png"
import vivre from "../../media/ICONS THEMATIQUE/Fichier 4.png"
import ajouterth from "../../media/ICONS THEMATIQUE/ajouter une thématique_1.png"
import info from "../../media/ICONS THEMATIQUE/info.png"
import Planete from "../../media/ICONS THEMATIQUE/monde.png"
// import { useLocation } from 'react-router-dom';
import userApi from "../../HTTP/user"
import "./side.css";
import * as helpers from "../../Helper/utils"; // Importez tout le module helper

const user = JSON.parse(localStorage.getItem("user"))

const Side = (props) => {
  const lowerSection = props.sideBarLowerSection
  const { SetFil } = useContext(Context);
  const { friendAction } = useContext(Context);

  const [open, setOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  // const location = useLocation();

  const [friends, setFriends] = useState([]);
  //const [inviteAction, toggleInviteAction] = useState(false);

  useEffect(() => {
      // if (location.pathname === "/friends") {
      //   // userApi.getAllFriends().then((f) => setFriends(f))
      // }
  }, [friendAction])
  

  const handleClick = () => {
    setOpen(!open);
  };


  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
     userApi.searchFriends(searchTerm).then((f) => setFriends(f))
  }


  return (
    <List
      sx={{  bgcolor: "#f6f4f5" }}
      component="nav"
      aria-labelledby="nested-list-subheader" 
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" onClick={() => window.location.href = "/"}>
          <img
            src={Logo}
            className="logo"
            style={{ width: "220px", marginTop: "0",   maxWidth: "80%", marginBottom: "15px" }}
            alt="Logo"
          />
        </ListSubheader>
      }
    >
      {
        <h3 className="text-center">
          Pays : {JSON.parse(sessionStorage.getItem("search_country")).name}
        </h3>
      }
      <ListItemButton className="sideMenuButton" onClick={() => window.location.href = "/feed"} >
        <img className="imgicon" src={h} alt="Icon" />
        <span className="spanacc">Fil d’actualité</span>
      </ListItemButton>

      <ListItemButton className="sideMenuButton" onClick={() => window.location.href = "/favourites"}>
        <img className="imgiconfav" src={bookmark} alt="Icon"  />
        <span className="spanacc">Articles favoris</span>
      </ListItemButton>

      <ListItemButton className="sideMenuButton" onClick={() => window.location.href = "/friends"}>
        <img className="imgicon" src={amis} alt="Icon"  />
        <span className="spanacc">Amis</span>
      </ListItemButton>

      <ListItemButton className="sideMenuButton" onClick={() => window.location.href = "/followed"}>
        <img className="imgicon" src={etoille} alt="Icon"  />
        <span className="spanacc">Suivis</span>
      </ListItemButton>

      <ListItemButton className="sideMenuButton" onClick={() => window.location.href = "/friends-msg"}>
        <img className="imgiconMessage" src={message} alt="Icon"  />
        <span className="spanacc">Message</span>
      </ListItemButton>

      <ListItemButton className="sideMenuButton" onClick={() => window.location.href = "/profile"}>
        <img className="iconProfil" src = { user.profile_picture || process.env.PUBLIC_URL+"/profile_pic_default_3.jpg"  } alt="Profile pic"  />
        <span className="spanacc">Profil</span>
      </ListItemButton>


      {
        lowerSection === "thematics" && (
          <>
            {" "}
            <ListItemButton x={{  marginBottom: "-8px" }}>
              <span className="title">Thématiques</span>
            </ListItemButton>
            <ListItemButton sx={{  marginBottom: "8px" }}>
              <img className="imgicon" src={Chroniques} alt="Icon" />
              <span className="spanacc">Chroniques</span>
            </ListItemButton>
            <ListItemButton sx={{  marginTop: "-12px" }}>
              <img className="imgicon" src={etoille} alt="Icon" /> <span className="ff">
                {" "}
                Politique
                Choix Élections
              </span>
            </ListItemButton>
            <ListItemButton sx={{  marginTop: "-12px" }}>
              <img className="imgicon" src={Noetoille} alt="Icon" /> <span className="ff">
                {" "}
                Économie
                Finance
              </span>
            </ListItemButton>
            <ListItemButton sx={{  marginTop: "-12px" }}>
              <img className="imgicon" src={Noetoille} alt="Icon" /> <span className="ff">
                {" "}
                Contrats
                Investissements
              </span>
            </ListItemButton>
            <ListItemButton sx={{  marginTop: "-12px" }}>
              <img className="imgicon" src={Noetoille} alt="Icon" /> <span className="ff">
                {" "}
                Affaires
                étrangères
              </span>
            </ListItemButton>
            <ListItemButton sx={{  marginTop: "-12px" }}>
              <img className="imgicon" src={etoille} alt="Icon" /> <span className="ff">
                {" "}
                Fête
                Commémoration
              </span>
            </ListItemButton>
            <ListItemButton sx={{  marginTop: "-12px" }}>
              <img className="imgicon" src={etoille} alt="Icon"  /> <span className="ff">
                {" "}
                Théâtre
                Concert Exposition
              </span>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                try {
                  SetFil("Société");
                } catch (error) {
                  window.location.replace("/");
                }
              }}

            >
              <img className="imgicon" src={Societe} alt="Icon" />
              <span className="spanacc">Société</span>
            </ListItemButton>

            <ListItemButton>
              <img className="imgicon" src={culture} alt="Icon" />
              <span className="spanacc">Culture</span>
            </ListItemButton>

            <ListItemButton>
              <img className="imgicon" src={vivre} alt="Icon" />
              <span className="spanacc">Vivre ensemble</span>
            </ListItemButton>

            <ListItemButton>
              <img className="imgicon" src={Planete} alt="Icon" />
              <span className="spanacc">Planète</span>
            </ListItemButton>
            <ListItemButton>
              <img className="imgicon" src={ajouterth} alt="Icon" />
              <span className="spanacc">Ajouter un thématique</span>
            </ListItemButton>

            <ListItemButton onClick={handleClick}>
              <img className="imgicon" src={info} alt="Icon" />
              <span className="spanacc">Informations</span>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4,  marginTop: "-12px" }}
                >
                  <span className="ff">
                    Nom : <span className="spblack">France</span>{" "}
                  </span>
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4,  marginTop: "-12px" }}
                >
                  <span className="ff">
                    Capital : <span className="spblack">Paris</span>{" "}
                  </span>
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4,  marginTop: "-12px" }}
                >
                  <span className="ff">
                    Population : <span className="spblack">67391582</span>
                  </span>
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4,  marginTop: "-12px" }}
                >
                  <span className="ff">
                    Gentilé: <span className="spblack">Français</span>
                  </span>
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4,  marginTop: "-12px" }}
                >
                  <span className="ff">
                    Devise : <span className="spblack">Euro</span>
                  </span>
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4,  marginTop: "-12px" }}
                >
                  <span className="ff">
                    Langue :<span className="spblack"> Français</span>
                  </span>
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4,  marginTop: "-12px" }}
                >
                  <span className="ff">
                    Région : <span className="spblack">Europe</span>{" "}
                  </span>
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4,  marginTop: "-12px" }}
                >
                  <span className="ff">
                    Sous-région :{" "}
                    <span className="spblack">Europe de l’Ouest</span>
                  </span>
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4,  marginTop: "-12px" }}
                >
                  <span className="ff">
                    Téléphone : <span className="spblack"> +33</span>
                  </span>
                </ListItemButton>
              </List>
            </Collapse>{" "}
          </>
        )
      }

      {
        lowerSection === "friends_requests" && (
          <>
            <div className="friends_request_search">
                <Paper
                  component="form"
                  id="search_bar"
                  onSubmit={handleSubmit}
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "313px",
                    marginTop: "20px",
                    borderRadius: "67px",
                    border: "1px solid #00D5F3",
                    marginLeft: "21px",
                    height: "40px",
                    marginRight: "9px"
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Recherche amis ..."
                    inputProps={{ "aria-label": "Rechercher" }}
                    value={searchTerm}
                    onChange={handleInputChange}
                  />
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
 
            </div>

            <div className="listamisdiv">
              <ul id="friend-list">
                {
                  friends.map((f) => 
                      <li className="friendsent" key={Math.random() * 69}>
                        <img src={f.profile_picture || process.env.PUBLIC_URL + "/profile_pic_default_2.svg"} alt="Profile pic" />
                        <div className="name">
                          <p>{helpers.truncateString(f.firstname, 10)} {helpers.truncateString(f.lastname, 10)}</p> 
                        </div>
                      </li>
                    )
                }
              </ul>
            </div>

          </>
        )
      }

    </List>
  );
};

export default Side;
